import { getFiles } from 'api/api';

export const FILES_FETCHING = 'FILES_FETCHING';
export const FILES_FETCHING_SUCCESS = 'FILES_FETCHING_SUCCESS';
export const FILES_FETCHING_FAILURE = 'FILES_FETCHING_FAILURE';
export const FILES_CLEARING = 'FILES_CLEARING';


export const defaultState = {
  isFetching: false,
  files: {
    basics: [],
    integration: [],
  },
  loaded: false,
};

export const clearFiles = () => ({
  type: FILES_CLEARING,
});

export const setFiles = data => ({
  type: FILES_FETCHING_SUCCESS,
  data,
});

export const fetchFiles = jwtToken => async (dispatch) => {
  dispatch({ type: FILES_FETCHING });

  try {
    const data = await getFiles(jwtToken);
    const sorted = {
      ...data,
      // sort by date descending
      basics: data.basics.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)),
      integration: data.integration.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)),
    };
    return dispatch(setFiles(sorted));
  } catch (e) {
    return dispatch({ type: FILES_FETCHING_FAILURE });
  }
};

export default function files(state = defaultState, action) {
  switch (action.type) {
    case FILES_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FILES_FETCHING_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        loaded: true,
        files: action.data,
      };
    }
    case FILES_FETCHING_FAILURE: {
      return {
        ...state,
        loaded: true,
      };
    }
    case FILES_CLEARING: {
      return {
        ...defaultState,
      };
    }
    default:
  }
  return state;
}
