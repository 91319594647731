// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-sales-jsx": () => import("./../../../src/pages/contact-sales.jsx" /* webpackChunkName: "component---src-pages-contact-sales-jsx" */),
  "component---src-pages-contact-sales-success-jsx": () => import("./../../../src/pages/contact-sales/success.jsx" /* webpackChunkName: "component---src-pages-contact-sales-success-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-dashboard-profile-jsx": () => import("./../../../src/pages/dashboard/Profile.jsx" /* webpackChunkName: "component---src-pages-dashboard-profile-jsx" */),
  "component---src-pages-dashboard-sign-out-jsx": () => import("./../../../src/pages/dashboard/SignOut.jsx" /* webpackChunkName: "component---src-pages-dashboard-sign-out-jsx" */),
  "component---src-pages-documentation-jsx": () => import("./../../../src/pages/documentation.jsx" /* webpackChunkName: "component---src-pages-documentation-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-language-jsx": () => import("./../../../src/pages/language.jsx" /* webpackChunkName: "component---src-pages-language-jsx" */),
  "component---src-pages-solutions-jsx": () => import("./../../../src/pages/solutions.jsx" /* webpackChunkName: "component---src-pages-solutions-jsx" */),
  "component---src-templates-docs-template-jsx": () => import("./../../../src/templates/DocsTemplate.jsx" /* webpackChunkName: "component---src-templates-docs-template-jsx" */)
}

