import { I18n } from 'aws-amplify';
import LocalizedStrings from 'react-localization';

import stringData from '../localization/localizationData';

const strings = new LocalizedStrings(stringData);

export const GetLocaleString = () => {
  const locale = strings.getLanguage();
  const locales = {
    en: 'English',
    de: 'Deutsch',
    // fr: 'Français',
    // es: 'Español',
    // it: 'Italiano',
    // ja: '日本語',
    // ko: '한국어',
    // ru: 'Русский',
    zh: '中文简体',
  };
  return locales[locale] || 'English';
};

export const changeLocale = (user, event) => {
  strings.setLanguage(event.currentTarget.id);
  I18n.setLanguage(event.currentTarget.id);
  if (typeof window !== 'undefined' && window.localStorage !== undefined) {
    window.localStorage.setItem('locale', event.currentTarget.id);
    window.location.reload();
  }
};

export default strings;
