/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { Hub } from '@aws-amplify/core';

import './src/helper/fonts/fonts.css';

import WrapRoot from './src/components/WrapRoot';
import WrapPage from './src/components/WrapPage';

// https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
export const wrapRootElement = WrapRoot;

// https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
export const wrapPageElement = WrapPage;

const PREFIX = process.env.GATSBY_PATH_PREFIX;

export const onPreRouteUpdate = ({ location: { pathname }, prevLocation }) => {
  // remove path prefix if it's on the path
  // everything listening to this should receive a consistent pathname
  // regardless of whether we're on staging or not
  const path = pathname.replace(`${PREFIX}/`, '/');

  Hub.dispatch('Routing', {
    event: 'routeChange',
    data: {
      from: prevLocation ? prevLocation.pathname : null,
      to: path,
    },
  });
};
