import React from 'react';
import { Container } from './Grid';

export default () => (
  <footer>
    <Container py={60} style={{ textAlign: 'center', display: 'block' }}>
      {
        `© ${new Date().getFullYear()} Mimi Hearing Technologies GmbH`
      }
    </Container>
  </footer>
);
