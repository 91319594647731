import { combineReducers } from 'redux';
import docs from './docs';
import files from './files';
import user from './user';

export default combineReducers({
  docs,
  files,
  user,
});
