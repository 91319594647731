/* eslint-disable import/prefer-default-export */
export const iOSDocsLink = 'https://mimihearingtechnologies.github.io/SDKDocs-iOS/master/';
export const androidDocsLink = 'https://mimihearingtechnologies.github.io/SDK-Android/latest';

export const AUTHED_ROUTES = {
  dashboard: '/dashboard',
  signout: '/dashboard/signout',
};

export const UNAUTH_ROUTES = {
  testEvaluate: '/solutions/test-evaluate',
  implementMimi: '/solutions/implement-mimi',
  contactSuccess: '/contact-sales/success',
};

export const LOGIN_ROUTE = '/dashboard';

export const mimiPPLink = 'https://www.mimi.io/en/privacy/';
export const mimiToULink = 'https://www.mimi.io/en/terms/';
export const supportPortalLink = 'https://gomimi.atlassian.net/servicedesk/customer/portal/1';
export const siteLink = 'integrate.mimi.io';
export const siteLinkDocs = 'integrate.mimi.io/documentation';

export const ConsumerAudioOptions = [
  { value: 'ConsumerAudio&Electronics.Headphones', label: 'headphones' },
  { value: 'ConsumerAudio&Electronics.Mobile', label: 'mobile' },
  { value: 'ConsumerAudio&Electronics.Speakers', label: 'speakers' },
  { value: 'ConsumerAudio&Electronics.Streaming', label: 'streaming' },
  { value: 'ConsumerAudio&Electronics.Televisions', label: 'television' },
  { value: 'ConsumerAudio&Electronics.Wearables', label: 'wearables' },
  { value: 'ConsumerAudio&Electronics.Other', label: 'other' },
];

export const InterfacesEnvironmentOptions = [
  { value: 'Interfaces&Environments.Automotive', label: 'automotive' },
  { value: 'Interfaces&Environments.In-flight', label: 'inFlight' },
  { value: 'Interfaces&Environments.Industry', label: 'industry' },
  { value: 'Interfaces&Environments.Voice', label: 'voice' },
  { value: 'Interfaces&Environments.VoIP', label: 'voip' },
  { value: 'Interfaces&Environments.Other', label: 'other' },
];

export const OsOptions = [
  { value: 'OperatingSystems&SoC.Audio Software', label: 'audioSoftware' },
  { value: 'OperatingSystems&SoC.OS', label: 'os' },
  { value: 'OperatingSystems&SoC.SoC', label: 'soc' },
  { value: 'OperatingSystems&SoC.Other', label: 'other' },
];

export const selectOptionGroups = [
  {
    label: 'consumerAudioElectronicsTitle',
    options: ConsumerAudioOptions,
  },
  {
    label: 'interfacesEnvironmentsTitle',
    options: InterfacesEnvironmentOptions,
  },
  {
    label: 'operatingSystemsTitle',
    options: OsOptions,
  },
];

export const overviewPDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/solution_integration_guide.pdf';

export const televisionPDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/consumer-audio-electronics/tv_integration_guide.pdf';
export const smartphonePDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/consumer-audio-electronics/smartphone_integration_guide.pdf';
export const headphonesPDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/consumer-audio-electronics/headphone_integration_guide.pdf';
export const speakerPDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/consumer-audio-electronics/speaker_integration_guide.pdf';
export const streamingPDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/consumer-audio-electronics/streaming_integration_guide.pdf';

export const automotivePDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/interfaces-environments/automotive_integration_guide.pdf';
export const inflightPDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/interfaces-environments/inflight_integration_guide.pdf';

export const osPDF = 'https://partner-portal-resources.s3.eu-central-1.amazonaws.com/public/integration-guides/operating-systems-soc/os_integration_guide.pdf';
