import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import { DropShadow, FONT_SIZE_DOCS_COPY, JAVA_GREEN, MEDIUM_STONE, HUGE_STONE } from 'helper/constants/styles';

import DropdownArrow from 'images/dropdown_arrow.jsx';

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 2vh;
  max-width: 100%;
`;

const DropdownLinks = styled.div`
  display: flex;
  padding: 0.8rem 0 0.5rem;
  flex-direction: column;

  > a {
    padding: 0.6rem 1.8rem 0.6rem 1rem;
    display: flex;
    color: #fff;
    font-size: 1em;
    text-decoration: none;
    align-items: center;

    &:hover {
      color: ${JAVA_GREEN};
      background-color: ${MEDIUM_STONE};
    }
  }
`;

const ListElement = styled.li`
  display: flex;
  margin-right: 2rem;
  margin-bottom: 0;

  &:hover {
    cursor: pointer;
    > a {
      color: ${JAVA_GREEN};
    }
  }

  &:hover path {
    fill: ${JAVA_GREEN};
  }
`;

const NavEntry = styled.a`
  color: #fff;
  font-size: ${FONT_SIZE_DOCS_COPY};
  text-decoration: none;
`;

const ArrowContainer = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
`;

const Arrow = styled.div`
  transition: transform 0.4s;
  ${props => props.spin && css`
    transform-origin: 50% 50%;
    transform: rotate(180deg);
  `}
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: ${props => (props.open ? 'visible' : 'hidden')};
  overflow-y: ${props => (props.open ? 'visible' : 'hidden')};
  position: absolute;
  top: 4.5rem;
  height: ${props => (props.open ? 'fit-content' : 0)};
  user-select: none;
  -webkit-overflow-scrolling: touch;
  background-color: ${HUGE_STONE};
  border-radius: 8px;
  margin-left: -2rem;
  filter: ${DropShadow};

  &::after,
  &::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-bottom-color: ${HUGE_STONE};
    border-width: 15px;
    margin-left: -15px;
  }
  &::before {
    border-bottom-color: ${HUGE_STONE};
    border-width: 15px;
    margin-left: -15px;
  }
`;

const DropdownMenu = ({
  Links,
  id,
  title,
  Img,
}) => {
  const node = useRef();
  const [dropdownFolded, setDropdownFolded] = useState(true);

  const navToggle = (e) => {
    e.preventDefault();
    if (!node.current.contains(e.target)) {
      setDropdownFolded(true);
    }
  };

  const resetDropdown = () => {
    setDropdownFolded(!dropdownFolded);
  };

  useEffect(() => {
    if (!dropdownFolded) {
      document.addEventListener('mousedown', navToggle);
      document.addEventListener('touchstart', navToggle);
    }

    return () => {
      document.removeEventListener('mousedown', navToggle);
      document.removeEventListener('touchstart', navToggle);
    };
  }, [!dropdownFolded]);

  const qaId = title !== undefined ? title.split(' ').join('-').toLowerCase() : id;

  return (
    <Container ref={node}>
      <ListElement onClick={() => setDropdownFolded(!dropdownFolded)}>
        <NavEntry id={`qa-nav-${qaId}-link`}>
          {Img !== undefined && <Img />}
          {title}
        </NavEntry>
        <ArrowContainer>
          <Arrow spin={!dropdownFolded}>
            <DropdownArrow />
          </Arrow>
        </ArrowContainer>
      </ListElement>
      <DropdownContainer id={`qa-nav-${qaId}-dropdown-open`} open={!dropdownFolded}>
        <DropdownLinks onClick={resetDropdown}>
          <Links />
        </DropdownLinks>
      </DropdownContainer>
    </Container>
  );
};

export default DropdownMenu;
