import styled, { css } from 'styled-components';

import { largeTablet } from 'helper/constants/mediaRules';
import { COLOR_DOCS_LINKS_BG, navHeight } from 'helper/constants/styles.js';
import { Col } from 'components/Layout/Grid';

const Sidebar = styled(Col)`
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  background-color: ${COLOR_DOCS_LINKS_BG};
  padding: 0;
  ${largeTablet(css`
    height: 100%;
    min-height: calc(100vh - ${navHeight});
  `)}
`;

export default Sidebar;
