import Auth from '@aws-amplify/auth';

const { LAMBDA_API_URL } = require('../../serverless/shared-constants');

export const USER_FETCHING = 'USER_FETCHING';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_PREVIOUS_SESSION = 'SET_PREVIOUS_SESSION';

export const defaultState = {
  isFetching: false,
  loggedIn: false,
  loaded: false,
  isPreviousSession: false,
  attributes: {},
};

const getAttributes = (signInUserSession) => {
  if (
    signInUserSession &&
    signInUserSession.idToken &&
    signInUserSession.idToken.payload
  ) {
    const { payload } = signInUserSession.idToken;
    const companyName = (payload['cognito:groups'] || []).find(group => group.startsWith('partner_'));
    // Value of 'locale' stored in localStorage add for use in user attributes, default = 'en-US'
    const userLanguage = typeof window !== 'undefined' && window.localStorage !== 'undefined' ? window.localStorage.getItem('locale') : null;

    return {
      companyName: companyName ? companyName.replace('partner_', '') : '',
      firstName: payload.given_name ? payload.given_name : '',
      lastName: payload.family_name ? payload.family_name : '',
      groups: payload['cognito:groups'] || [],
      // is either default 'sdk' or only 'healthsdk' for now
      // strip out the 'flavor_' prefix if it exists
      sdkFlavor: (payload['cognito:groups'].find(group => group.includes('flavor_')) || 'sdk').replace('flavor_', ''),
      userLanguage,
    };
  }
  return {};
};

export const fetchingUser = () => ({ type: USER_FETCHING });
export const failureFetchingUser = () => ({ type: USER_FETCH_FAILURE });
export const successFetchingUser = (userData) => {
  const { signInUserSession, attributes: { email } } = userData;

  const jwtToken = signInUserSession &&
    signInUserSession.accessToken &&
    signInUserSession.accessToken.jwtToken;

  return ({
    type: USER_FETCH_SUCCESS,
    attributes: {
      email,
      ...getAttributes(signInUserSession),
      jwtToken,
      cognito: userData,
    },
  });
};

export const logoutUser = () => ({ type: USER_LOGOUT });

export const isLoggedIn = userData => userData.loggedIn;
export const unAuthed = userData => userData.loaded && !userData.loggedIn;

export const userGradleDistibutionURL = (userData) => {
  if (userData.attributes && userData.attributes.sdkFlavor) {
    return `${LAMBDA_API_URL}/files/${userData.attributes.sdkFlavor}/android`;
  }
  return `${LAMBDA_API_URL}/files/sdk/android`;
};

export const getCurrentUser = () => async (dispatch, getState) => {
  dispatch(fetchingUser());

  try {
    const data = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    // check to see if authentication has loaded before
    // this conditional is not reached if Auth.currentAuthenticatedUser failed,
    // in which case state.user.isPreviousSession remains false
    if (!getState().user.loaded && typeof window !== 'undefined' && window.localStorage !== 'undefined') {
      // this is a return visit if there is a key in localStorage where the email value equals
      // the same email we just received from Auth.currentAuthenticatedUser.
      // this would remain false only if you just logged in with a different user (rare case)
      const isReturnVisit = !!Object.keys(window.localStorage).find(key =>
        key.startsWith('CognitoIdentityServiceProvider') &&
        key.endsWith('LastAuthUser') &&
        window.localStorage.getItem(key) === data.attributes.email,
      );

      dispatch({
        type: SET_PREVIOUS_SESSION,
        isPreviousSession: isReturnVisit,
      });
    }

    return dispatch(successFetchingUser(data));
  } catch (e) {
    return dispatch(failureFetchingUser());
  }
};

export const userLogout = () => dispatch => dispatch(logoutUser());

export default function user(state = defaultState, action) {
  switch (action.type) {
    case USER_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case USER_FETCH_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        isFetching: false,
        loaded: true,
      };
    }
    case USER_FETCH_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        isFetching: false,
        loaded: true,
        attributes: action.attributes,
      };
    }
    case USER_LOGOUT: {
      return defaultState;
    }
    case SET_PREVIOUS_SESSION: {
      return {
        ...state,
        isPreviousSession: action.isPreviousSession,
      };
    }
    default:
      return state;
  }
}
