import Amplify from 'aws-amplify';

const {
  COGNITO_USER_POOL_ID_STAGING,
  COGNITO_USER_POOL_WEB_CLIENT_ID_STAGING,
  COGNITO_USER_POOL_ID_PRODUCTION,
  COGNITO_USER_POOL_WEB_CLIENT_ID_PRODUCTION,
} = require('../../serverless/shared-constants');

if (typeof window !== 'undefined' && process.env.GATSBY_SHOW_AMPLIFY_LOGS) {
  window.LOG_LEVEL = 'DEBUG';
}

const envConfig = {
  production: {
    userPoolId: COGNITO_USER_POOL_ID_PRODUCTION,
    userPoolWebClientId: COGNITO_USER_POOL_WEB_CLIENT_ID_PRODUCTION,
  },
  staging: {
    userPoolId: COGNITO_USER_POOL_ID_STAGING,
    userPoolWebClientId: COGNITO_USER_POOL_WEB_CLIENT_ID_STAGING,
  },
};

const config = process.env.GATSBY_DEPLOY_ENVIRONMENT === 'PRODUCTION' ?
  envConfig.production : envConfig.staging;

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
  },
});
