import en from './languages/en-US.json';
import de from './languages/de-DE.json';
// import es from './languages/es-ES.json';
// import fr from './languages/fr-FR.json';
// import it from './languages/it-IT.json';
// import ja from './languages/ja-JP.json';
// import ko from './languages/ko-KR.json';
// import ru from './languages/ru-RU.json';
import zh from './languages/zh-CN.json';

const stringData = {
  en,
  de,
  // es,
  // fr,
  // it,
  // ja,
  // ko,
  // ru,
  zh,
};

export default stringData;
