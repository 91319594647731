import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import NavBar from 'components/Navbar/Navbar';
import { BREAKPOINTS, navHeight } from 'helper/constants/styles';
import GlobalStyle from './GlobalStyle';
import BreakpointHelper from './BreakpointHelper';

const theme = {
  space: [0, 6, 12, 18, 24],
  breakpoints: BREAKPOINTS,
};

class Layout extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mobileNavFolded: true,
    };
  }

  resetNav = () => {
    if (typeof this.props.location !== 'undefined') {
      this.setState({ mobileNavFolded: true });
    }
    return false;
  }

  toggleMobileNav = () => {
    this.setState(prevState => ({
      mobileNavFolded: !prevState.mobileNavFolded,
    }));
  };

  render() {
    const { children } = this.props;
    const { mobileNavFolded } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <>
          { process.env.NODE_ENV === 'development' && <BreakpointHelper /> }
          <GlobalStyle />
          <NavBar
            mobileNavFolded={mobileNavFolded}
            mobileNavToggle={this.toggleMobileNav}
            resetNav={this.resetNav}
          />
          <main id="main" style={{ paddingTop: navHeight }}>{children}</main>
        </>
      </ThemeProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
