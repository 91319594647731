// place constants shared between gatsby and serverless here

const COGNITO_USER_POOL_ID_STAGING = 'eu-central-1_VRw6SBZZK';
const COGNITO_USER_POOL_WEB_CLIENT_ID_STAGING = '1b76rcnftn644gpiq242lsc6jd';
const COGNITO_USER_POOL_ID_PRODUCTION = 'eu-central-1_1lugzIVrn';
const COGNITO_USER_POOL_WEB_CLIENT_ID_PRODUCTION = '3122s8juh12e2asmtdkdar58ss';

const LAMBDA_API_URL = process.env.GATSBY_API_URL || 'http://localhost:3000';

module.exports = {
  COGNITO_USER_POOL_ID_STAGING,
  COGNITO_USER_POOL_WEB_CLIENT_ID_STAGING,
  COGNITO_USER_POOL_ID_PRODUCTION,
  COGNITO_USER_POOL_WEB_CLIENT_ID_PRODUCTION,
  LAMBDA_API_URL,
};
