import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

import { largeTablet } from 'helper/constants/mediaRules';
import { navHeight, zIndexes, COLOR_GENERIC_BG } from 'helper/constants/styles';
import { isLoggedIn } from 'state/user';
import { Container } from 'components/Layout/Grid';
import mimiLogo from 'S3/images/mimi_logo.svg';
import MobileNavBar from './MobileNavbar';
import NavLinks from './NavLinks';

const StyledNavBarContainer = styled.div`
  height: ${navHeight};
  margin-bottom: 2vh;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  z-index: ${zIndexes.navBar};
  background-color: ${COLOR_GENERIC_BG};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledNavBar = styled(Container)`
  display: flex;
`;

const DesktopNavBar = styled.div`
  display: none;
  ${largeTablet(css`
    display: flex;
    width: 100%;
  `)}
`;

const StyledLogoImg = styled.img`
  width: 8rem;
  padding-top: 6px;
  ${largeTablet(css`
    width: 6rem;
    padding-top: 12px;
  `)}
`;

const NavBar = ({ mobileNavFolded, mobileNavToggle, resetNav, user }) => (
  <StyledNavBarContainer>
    <StyledNavBar as="nav">
      <Link to="/" onClick={resetNav}>
        <StyledLogoImg id="qa-mimi-logo" src={mimiLogo} alt="Mimi Logo" />
      </Link>
      <DesktopNavBar id="qa-desktop-nav">
        <NavLinks screen={'desktop'} user={user} />
      </DesktopNavBar>
      <MobileNavBar
        mobileNavToggle={mobileNavToggle}
        mobileNavFolded={mobileNavFolded}
        resetNav={resetNav}
      />
      {/* hidden until search implemented
        <img alt="search button" id="search-icon" src={SearchIcon} />
      */}
    </StyledNavBar>
  </StyledNavBarContainer>
);

export default connect(({ user }) => ({
  user: isLoggedIn(user),
}))(NavBar);
