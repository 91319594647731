import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import Collapse, { Panel } from 'rc-collapse';
import styled, { css } from 'styled-components';
import { Hub } from '@aws-amplify/core';

import DocsVersionSwitcher from 'components/DocsVersionSwitcher';
import { desktop } from 'helper/constants/mediaRules';
import DropdownArrow from 'images/dropdown_arrow';
import {
  SideNavContainer,
  SideNavUl,
  SideNavItem,
} from 'components/PageTemplates/SidebarNavPage/SidebarLinksUi';

const Accordion = styled(Collapse)`
  .rc-collapse-content {
    padding-left: 20px;
    overflow: hidden;
  }
  .rc-collapse-header {
    position: relative;
  }
  .rc-collapse-content-inactive {
    display: none;
  }
  .rc-collapse-anim-active {
    transition: height 0.2s ease-out;
  }
`;

const selectVersion = ({ value }) => {
  navigate(value);
};

const IconContainer = styled(DropdownArrow)`
  position: absolute;
  top: 18px;
  left: 6px;
  font-size: 20px;
  transition: transform .2s;
  transform-origin: 50% 50%;
  transform: ${props => (props.active ? 'rotate(0)' : 'rotate(-90deg)')};
  .rc-collapse-content & {
    top: 15px;
  }

  ${desktop(css`
    left: 18px;
  `)}
`;

const expandIcon = ({ isActive }) => (
  <IconContainer
    active={isActive ? 'true' : undefined}
  />
);

const LinksGenerator = ({
  items,
  route,
  level = 0,
  activePath,
  activeKeys,
  changeActiveKeys,
}) => {
  const defaultKey = activeKeys[level];
  const defaultSlug = defaultKey > -1 ? [items[defaultKey].slug] : undefined;

  const rendered = items.map((item, index) => {
    const { slug, title, children } = item;
    const renderedTitle = title.replace(/\./g, '.<wbr>');
    const isActive = index === defaultKey;

    const ItemElement = (
      <SideNavItem
        key={title}
        active={slug === route ? 'true' : undefined}
        onClick={() => {
          const isOpen = children && activeKeys[level] === index;
          if (isOpen) {
            return changeActiveKeys(slug.split('/').slice(0, -1).join('/'));
          }
          return changeActiveKeys(slug);
        }}
      >
        {
          children ?
            // eslint-disable-next-line react/no-danger
            (<span dangerouslySetInnerHTML={{ __html: renderedTitle }} />) :
            (<Link to={slug} dangerouslySetInnerHTML={{ __html: renderedTitle }} />)
        }
      </SideNavItem>
    );

    if (children && isActive) {
      const childDefaultKey = activeKeys[level + 1];
      const childDefaultSlug = childDefaultKey > -1 ? [children[childDefaultKey].slug] : undefined;

      return (
        <Panel header={ItemElement} key={slug}>
          <Collapse
            expandIcon={expandIcon}
            accordion
            destroyInactivePanel
            key={title}
            activeKey={childDefaultSlug}
          >
            {
              LinksGenerator({
                items: item.children,
                level: level + 1,
                route,
                activePath,
                activeKeys,
                changeActiveKeys,
              })
            }
          </Collapse>
        </Panel>
      );
    }

    return (
      <Panel key={title} showArrow={!!children} header={ItemElement} />
    );
  });

  if (level === 0) {
    return (
      <Accordion
        expandIcon={expandIcon}
        accordion
        destroyInactivePanel
        activeKey={defaultSlug}
      >
        { rendered }
      </Accordion>
    );
  }

  return rendered;
};

export class DocsLinks extends Component {
  state = {
    route: '',
    activeKeys: [],
  };

  componentDidMount() {
    this.setRoute();
    Hub.listen('Routing', this.setRoute);
  }

  setActiveKeys = (items, route, keys = []) => {
    if (items) {
      const activeKey = items.findIndex(item => route.includes(item.slug));
      if (activeKey > -1) {
        return this.setActiveKeys(items[activeKey].children, route, [...keys, activeKey]);
      }
    }

    return this.setState({ activeKeys: keys.length ? keys : [-1] });
  }

  setRoute = () => {
    let route = typeof window !== 'undefined' ?
      window.location.pathname.replace(`${process.env.GATSBY_PATH_PREFIX}/`, '/') : '';

    // sometimes gatsby adds a / to the end of the url
    // all the docs slugs don't have this
    if (route.substring(route.length - 1) === '/') {
      route = route.substring(0, route.length - 1);
    }

    this.setState(
      { route },
      () => this.setActiveKeys(this.props.docs.sidebar, this.state.route),
    );
  }

  changeActiveKeys = (route) => {
    this.setActiveKeys(this.props.docs.sidebar, route);
  }

  render() {
    const { docs } = this.props;
    const { route, activeKeys } = this.state;
    const { changeActiveKeys } = this;

    return (
      <SideNavContainer>
        <DocsVersionSwitcher docs={docs} selectVersion={selectVersion} />
        <SideNavUl data-qa="chapter-list" className="docs-accordion">
          {
            activeKeys.length && // figure out how to avoid this
            LinksGenerator({
              items: docs.sidebar,
              route,
              activePath: docs.activePath,
              activeKeys,
              changeActiveKeys,
            })
          }
        </SideNavUl>
      </SideNavContainer>
    );
  }
}

export default connect(({ docs }) => ({ docs }))(DocsLinks);
