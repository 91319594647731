import styled, { css } from 'styled-components';
import { Box, Flex } from '@rebass/grid';
import { DEFAULT_GUTTER } from 'helper/constants/styles';
import { largeDesktop } from 'helper/constants/mediaRules';

const Container = styled(Flex)`
  max-width: 1200px;
  width: 100%;

  ${largeDesktop(css`
    max-width: 1400px;
  `)}
`;
Container.displayName = 'Container';
Container.defaultProps = {
  mx: 'auto',
  px: DEFAULT_GUTTER,
};

const Row = styled(Flex)`
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
`;
Row.displayName = 'Row';
Row.defaultProps = {
  mx: -DEFAULT_GUTTER,
};

const Col = Box;
Col.displayName = 'Col';
Col.defaultProps = {
  px: DEFAULT_GUTTER,
};


export {
  Col,
  Container,
  Row,
};
