import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Footer from 'components/Layout/Footer';

import { largeTablet, desktop } from 'helper/constants/mediaRules';
import {
  COLOR_CODE_BLOCK_BG,
  COLOR_GENERIC_TEXT,
  DEFAULT_VERTICAL_GUTTER,
  FONT_SIZE_DOCS_COPY,
  JAVA_GREEN,
} from 'helper/constants/styles';

import { Col, Container, Row } from 'components/Layout/Grid';
import Sidebar from 'components/Sidebar/Sidebar';
import SEO from 'components/Seo';

import Layout from 'components/Layout/Layout';

const PageWrapper = styled(Container) `
  ${largeTablet(css`
    min-height: 100%;
    flex-grow: 1;
  `)}
`;
PageWrapper.displayName = 'SidebarPage_Wrapper';

const SidebarRow = styled(Row)`
  height: auto;
  overflow-x: visible;
`;

const PageContent = styled.div`
  font-size: ${FONT_SIZE_DOCS_COPY};
  margin: ${DEFAULT_VERTICAL_GUTTER} 0;

  ${largeTablet(css`
    padding: 0 30px;
  `)}

  ${desktop(css`
    padding: 0 50px;
  `)}

  h2 {
    margin-top: 2.5rem;
  }

  pre[class*="language-"] {
    background-color: ${COLOR_CODE_BLOCK_BG};
    box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
  }

  a, a:visited {
    color: ${props => (props.isDocs ? COLOR_GENERIC_TEXT : JAVA_GREEN)};
    text-decoration: ${props => (props.isDocs ? 'underline' : 'none')};
    font-weight: ${props => (props.isDocs ? 700 : 400)};

    &:hover {
      text-decoration: underline;
    }
  }
`;
PageContent.displayName = 'SidebarPage_Content';

const ratio = 1 / 4;

/**
 * Page Layout component that piggybacks off of Gatsby's <Layout />
 *
 * PROPS:
 * 'children' - any wrapped comps,
 * 'location' - is auto-provided,
 * 'pageTitle' - name of the page for SEO
 */

const SidebarPageLayout = ({
  children,
  pageTitle,
  SideNavItems,
}) => (
  <Layout>
    <SEO title={pageTitle} />
    <PageWrapper>
      <SidebarRow>
        <Sidebar width={[1, 1, ratio]}>
          {SideNavItems}
        </Sidebar>
        <Col width={[1, 1, 1 - ratio]}>
          <PageContent>
            {children}
            <Footer />
          </PageContent>
        </Col>
      </SidebarRow>
    </PageWrapper>
  </Layout>
);

SidebarPageLayout.propTypes = {
  /** Docs Page contents are .md docs from MSDK */
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

SidebarPageLayout.defaultProps = {
  location: {},
};

export default SidebarPageLayout;
