import sidebar from '../../build/tmp/docs-sidebar';

const defaultState = {
  meta: Object.keys(sidebar).reduce((prev, current) => ({
    [current]: sidebar[current].map(p => ({
      version: p.version,
      firstPage: p.content[0].slug,
    })),
    ...prev,
  }), {}),
};

const SETUP_SIDEBAR = 'SETUP_SIDEBAR';

export const setupSidebar = (platform, version) => ({
  type: SETUP_SIDEBAR,
  platform,
  version,
});

export default function docs(state = defaultState, action) {
  switch (action.type) {
    case SETUP_SIDEBAR: {
      const { platform, version } = action;
      return {
        ...state,
        platform,
        version,
        sidebar: sidebar[platform].find(p => p.version === version).content,
      };
    }
    default:
      return state;
  }
}
