import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { androidDocsLink, iOSDocsLink } from 'helper/constants/constants';
import strings, { changeLocale } from 'helper/localization/localization.js';

import AndroidIcon from 'images/android_icon';
import IOSIcon from 'images/ios_icon';

import TestNavIcon from 'images/test_nav_icon';
import ImplementIcon from 'images/implement_nav_icon';

import { AUTHED_ROUTES } from 'helper/constants/constants';

const StyledDocText = styled.div`
  margin-left: 1.6em;
`;

const StyledIntegrateText = styled.div`
  margin-left: 1.4em;
`;

const LocaleLink = styled.a`
  cursor: pointer;
`;

export const DocsDropdownList = () => (
  <>
    <a id="qa-desktop-nav-ios-link" rel="noopener noreferrer" href={iOSDocsLink}>
      <IOSIcon />
      <StyledDocText>{strings.foriOS}</StyledDocText>
    </a>
    <Link id="qa-desktop-nav-android-link" to={androidDocsLink}>
      <AndroidIcon />
      <StyledDocText>{strings.forAndroid}</StyledDocText>
    </Link>
  </>
);

export const IntegrateDropdownList = () => (
  <>
    <Link id="qa-desktop-nav-test-our-tech-link" to="/solutions/test-evaluate">
      <TestNavIcon />
      <StyledIntegrateText>{strings.testEvaluate}</StyledIntegrateText>
    </Link>
    <Link id="qa-desktop-nav-build-with-mimi-link" to="/solutions/implement-mimi">
      <ImplementIcon />
      <StyledIntegrateText>{strings.implementMimi}</StyledIntegrateText>
    </Link>
  </>
);

export const ProfileDropdownList = () => (
  <>
    <Link id="qa-desktop-nav-dashboard-link" to={AUTHED_ROUTES.dashboard}>
      <StyledIntegrateText>{strings.profile}</StyledIntegrateText>
    </Link>
    <Link id="qa-desktop-nav-signout-link" to={AUTHED_ROUTES.signout}>
      <StyledIntegrateText>{strings.signOut}</StyledIntegrateText>
    </Link>
  </>
);

export const LocaleDropdownList = connect(({ user }) => ({ user }))(
  ({ user }) => (
    <>
      <LocaleLink role="button" tabIndex="0" id="en-EN" onClick={e => changeLocale(user, e)}>{'English'}</LocaleLink>
      <LocaleLink role="button" tabIndex="0" id="de-DE" onClick={e => changeLocale(user, e)}>{'Deutsch'}</LocaleLink>
      {/* <LocaleLink
        role="button"
        tabIndex="0"
        id="fr-FR"
        onClick={e => changeLocale(user, e)}
      >
        {'Français'}
      </LocaleLink>
      <LocaleLink
        role="button"
        tabIndex="0"
        id="es-ES"
        onClick={e => changeLocale(user, e)}
      >
        {'Español'}
      </LocaleLink>
      <LocaleLink
        role="button"
        tabIndex="0"
        id="it-IT"
        onClick={e => changeLocale(user, e)}
      >
        {'Italiano'}
      </LocaleLink>
      <LocaleLink
        role="button"
        tabIndex="0"
        id="ja-JP"
        onClick={e => changeLocale(user, e)}
      >
        {'日本語'}
      </LocaleLink>
      <LocaleLink
        role="button"
        tabIndex="0"
        id="ko-KR"
        onClick={e => changeLocale(user, e)}
      >
        {'한국어'}
      </LocaleLink>
      <LocaleLink
        role="button"
        tabIndex="0"
        id="ru-RU"
        onClick={e => changeLocale(user, e)}
      >
        {'Русский'}
      </LocaleLink> */}
      <LocaleLink role="button" tabIndex="0" id="zh-CN" onClick={e => changeLocale(user, e)}>{'中文简体'}</LocaleLink>
    </>
  ),
);
