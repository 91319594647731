import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  SideNavContainer,
  SideNavUl,
  SideNavTitle,
  SideNavItem,
} from 'components/PageTemplates/SidebarNavPage/SidebarLinksUi';

import strings from 'helper/localization/localization.js';
import TestSideIconWhite from 'images/test_side_icon_white.svg';
import TestSideIconSalmon from 'images/test_side_icon_salmon.svg';
import ImplementSideIconWhite from 'images/implement_side_icon_white.svg';
import ImplementSideIconSalmon from 'images/implement_side_icon_salmon.svg';

const integrateNavLinks = [
  {
    text: 'testEvaluate',
    iconActive: TestSideIconSalmon,
    iconIdle: TestSideIconWhite,
    slug: 'test-evaluate',
  },
  {
    text: 'implementMimi',
    iconActive: ImplementSideIconSalmon,
    iconIdle: ImplementSideIconWhite,
    slug: 'implement-mimi',
  },
];

const SidebarIcon = styled.img`
  margin-right: 0.6rem;
`;

const NavContents = ({ pathname }) => integrateNavLinks.map(link => (
  <Fragment key={link.text}>
    <SideNavItem active={pathname.includes(link.slug)}>
      <Link id={`qa-sidebar-${link.text.split(' ').join('-').toLowerCase()}`} to={`/solutions/${link.slug}`}>
        <SidebarIcon src={pathname.includes(link.slug) ? link.iconActive : link.iconIdle} alt="" />
        {strings[link.text]}
      </Link>
    </SideNavItem>
  </Fragment>
));

const IntegrateLinks = ({ pathname }) => (
  <SideNavContainer>
    <SideNavUl>
      <SideNavTitle>
        <span>{strings.integrateMimi}</span>
      </SideNavTitle>
      <NavContents pathname={pathname} />
    </SideNavUl>
  </SideNavContainer>
);

export default IntegrateLinks;

NavContents.propTypes = {
  pathname: PropTypes.string,
};

NavContents.defaultProps = {
  pathname: '',
};

IntegrateLinks.propTypes = {
  pathname: PropTypes.string,
};

IntegrateLinks.defaultProps = {
  pathname: '',
};
