import React from 'react';
import styled, { css } from 'styled-components';

import { largeTablet, desktop } from 'helper/constants/mediaRules';
import {
  BIG_STONE,
  COLOR_ACTIVE_LINK,
  COLOR_GENERIC_TEXT,
  DEFAULT_VERTICAL_GUTTER,
  FONT_SIZE_DOCS_LINKS_TOP_LEVEL,
  navHeight,
} from 'helper/constants/styles';

export const SideNavContainer = styled.nav`
  padding: ${DEFAULT_VERTICAL_GUTTER} 0;
  position: sticky;
  top: ${navHeight};
  overflow: auto;

  ${largeTablet(css`
    max-height: calc(100vh - ${navHeight});
    overflow-y: scroll;
  `)}
`;

export const SideNavUl = styled.ul`
  margin: 0 0 0 0;
  font-size: ${FONT_SIZE_DOCS_LINKS_TOP_LEVEL};
  font-weight: 400;
  list-style: none;
`;

export const SideNavTitle = styled.li`
  > a, > span {
    color: ${COLOR_GENERIC_TEXT};
    line-height: 2rem;
    display: block;
    margin-bottom: 0.5rem;
    padding: 0.4rem 1rem;
    font-weight: 700;
    text-decoration: none;
    width: fit-content;
    ${desktop(css`
      padding: 0.4rem 2rem;
    `)}
  }
`;

const SideNavItemElement = ({ className, children, onClick }) => <div onClick={onClick} className={className} role="button" tabIndex={0}>{children}</div>;

// Has to be setup in this weird shared span/<a> object to avoid the stylelint
// no-descending-specificity rule with the differing :visited rules for each
const sharedNavItemStyle = css`
  color: ${COLOR_GENERIC_TEXT};
  display: block;
  margin-bottom: 0.5rem;
  font-size: .8em;
  padding: 0.6rem 1rem;
  word-wrap: break-word;
  cursor: pointer;

  &:hover {
    background-color: ${({ active }) => (active ? null : '#15293c4d')};
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${largeTablet(css`
    font-size: 0.9em;
  `)}

  ${desktop(css`
    padding: 0.6rem 2rem;
    font-size: .8em;
  `)}
  .rc-collapse-content & {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    &,
    &:visited,
    &:hover {
      background: none !important;
    }
  }
`;

export const SideNavItem = styled(SideNavItemElement)`
  > a {
    ${sharedNavItemStyle}
    &,
    &:visited {
      color: ${({ active }) => (active ? COLOR_ACTIVE_LINK : COLOR_GENERIC_TEXT)};
      border-right: ${({ active }) => (active ? `1px solid ${COLOR_ACTIVE_LINK}` : null)};
      background-color: ${({ active }) => (active ? BIG_STONE : null)};
      text-decoration: none;
    }
  }

  > span {
    ${sharedNavItemStyle}
    &,
    &:visited {
      background-color: ${({ active }) => (active ? BIG_STONE : null)};
      text-decoration: none;
    }
  }
`;
