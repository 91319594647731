import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';

import { isLoggedIn } from 'state/user';
import { largeTablet } from 'helper/constants/mediaRules';
import { navHeight, zIndexes } from 'helper/constants/styles';
import chevronDown from 'S3/images/chevron_down.svg';
import NavLinks from './NavLinks';

const MobileContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: ${navHeight};
  margin-bottom: 2vh;
  max-width: 100%;
  width: 100%;
  z-index: ${zIndexes.navBar};
  ${largeTablet(css`
    display: none;
  `)}
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  flex-wrap: nowrap;
  align-items: center;
  transition: height 0.5s;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  height: ${props => (props.open ? '32rem' : 0)};
`;

const MobileNavButton = styled.button`
  flex: 0 0 auto;
  min-width: ${navHeight};
  width: ${navHeight};
  height: ${navHeight};
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background-color: inherit;

  &:active {
    background: rgba(0, 0, 0, 0.07);
  }
`;

const IconWrapper = styled.div`
  transition: transform 0.1s;
  ${props => props.spin && css`
    transform-origin: 50% 55%;
    transform: rotate(180deg);
  `}
`;

const ChevronStyled = styled.img`
  width: 40%;
`;

const MobileNavBar = ({ mobileNavFolded, mobileNavToggle, user }) => (
  <MobileContainer>
    <MobileNavButton
      id="qa-mobile-navbar-arrow"
      onClick={mobileNavToggle}
      active={!mobileNavFolded}
    >
      <IconWrapper spin={!mobileNavFolded}>
        <ChevronStyled alt="expand menu" src={chevronDown} />
      </IconWrapper>
    </MobileNavButton>
    <SubMenu id="qa-mobile-nav-drawer" open={!mobileNavFolded} onClick={mobileNavToggle}>
      <NavLinks screen={'mobile'} user={user} />
    </SubMenu>
  </MobileContainer>
);

export default connect(({ user }) => ({
  user: isLoggedIn(user),
}))(MobileNavBar);
