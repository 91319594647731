import React from 'react';
import styled from 'styled-components';

import DocsLinks from 'components/DocsLinks/DocsLinks';
import IntegrateLinks from 'components/IntegrateNavLinks/IntegrateLinks';

import SidebarPageLayout from 'components/PageTemplates/SidebarNavPage/SidebarPageLayout';

import { COLOR_GENERIC_BG } from 'helper/constants/styles';
import { UNAUTH_ROUTES } from 'helper/constants/constants';

import Footer from './Layout/Footer';
import Layout from './Layout/Layout';

const DocsBackground = styled.div`
  background-color: ${COLOR_GENERIC_BG};
`;

const WrapPage = ({ element, props }) => {
  // Return TRUE value if on any of the solutions subpages
  const solutionsPage = [
    props.location.pathname.includes(UNAUTH_ROUTES.testEvaluate),
    props.location.pathname.includes(UNAUTH_ROUTES.implementMimi),
  ].includes(true);

  // wraps (.md - MSDK Docs) content for all pages with /docs path
  if (props.location.pathname.includes('/documentation/android') || props.location.pathname.includes('/documentation/ios')) {
    return (
      <DocsBackground>
        <SidebarPageLayout
          pageTitle={'mSDK Docs'}
          SideNavItems={<DocsLinks />}
          {...props}
        >
          {element}
        </SidebarPageLayout>
      </DocsBackground>
    );
  } else if (solutionsPage) {
  // wraps integrate mimi pages content for all pages with /solutions in the path
    const IntegrateNavLinks = (
      <IntegrateLinks
        pathname={props.location.pathname}
      />
    );
    return (
      <SidebarPageLayout
        pageTitle={'Integrate Mimi'}
        SideNavItems={IntegrateNavLinks}
        {...props}
      >
        {element}
      </SidebarPageLayout>
    );
  }
  return (
    <Layout {...props}>
      {element}
      <Footer />
    </Layout>
  );
};

export default WrapPage;
