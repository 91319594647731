/* eslint-disable import/prefer-default-export */

/**
 * LAYOUT
 */
export const navHeight = '70px';
export const WIDTH_DOCS_LINKS = '25rem';
export const DEFAULT_GUTTER = 3;
export const DEFAULT_VERTICAL_GUTTER = '30px';
export const BREAKPOINTS = ['32em', '58.2em', '64em', '112em']; // [512px, 932px, 1024px, 1800px]

/**
 * Import any style constants here
 */

export const zIndexes = {
  backgroundShapes: -1,
  footerContent: 1,
  headerContent: 1,
  landingHeaderImageFront: 1,
  landingHeaderImageBack: 0,
  navBar: 1000,
};

export const DropShadow = 'drop-shadow(0 4px 5px rgba(0, 0, 0, 0.12)) drop-shadow(0 1px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0 2px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0 -1px 3px rgba(0, 0, 0, 0.1));';

/**
 * COLORS
 */

const WHITE = '#FFFFFF';
const TONYS_PINK = '#FFB9B9';
const CYPRUS_GREEN = '#122333';
const TIBER_GREEN = '#102131';
const MIDNIGHT_BLUE = '#20282f';
const WHALE_BLUE = '#1E364B';
const SMALL_STONE = '#192E41';
const SLATE_GREY = '#b9b9b9';
export const DOVE_GRAY = '#6f6f6f';
export const MEDIUM_STONE = '#142534';
export const BIG_STONE = '#15293C';
export const HUGE_STONE = '#172A3B';
export const BUNTING = '#111343';
export const JAVA_GREEN = '#21D7A2';
export const JAVA_HOVER = '#7cefcd';
export const JAVA_DARK_GREEN = '#189672';
export const MONA_LISA_PINK = '#FF9D9C';
export const SAN_JUAN = '#305170';
export const BORDER_GREEN = '#23D2A2';

export const COLOR_GENERIC_BG = TIBER_GREEN;
export const COLOR_CTA_HOVER_BG = TONYS_PINK;
export const COLOR_ACTIVE_LINK = MONA_LISA_PINK;
export const COLOR_DOCS_LINKS_BG = CYPRUS_GREEN;
export const COLOR_GENERIC_TEXT = WHITE;
export const COLOR_INACTIVE_TEXT = SLATE_GREY;
export const COLOR_CODE_BLOCK_BG = MIDNIGHT_BLUE;
export const COLOR_FORM_FIELD_BG = SMALL_STONE;
export const COLOR_FILE_BG = WHALE_BLUE;
export const COLOR_FORM_PLACEHOLDER = '#9E9EAF';
export const COLOR_CTA_TEXT = '#0D1040';
export const COLOR_CTA_DISABLED_BG = '#343542';
export const FORM_ERROR_RED = '#EB736A';

/**
 * TYPOGRAPHY
 */
// Sizes
const FONT_SIZE_BASE = '100%'; // Chrome is 16px
export const FONT_SIZE_ROOT = FONT_SIZE_BASE;
export const FONT_SIZE_DOCS_COPY = '1.125em';
export const FONT_SIZE_DOCS_LINKS = '0.9em';
export const FONT_SIZE_DOCS_LINKS_TOP_LEVEL = '1.25em';
export const FONT_SIZE_DOCS_H1 = '2em';
export const FONT_SIZE_DOCS_H2 = '1.4em';
export const FONT_SIZE_LANDING_HEADER_TITLE = '3.125em';
export const FONT_SIZE_404_TITLE_MOBILE = '5.5em';
export const FONT_SIZE_LANDING_HEADER_BODY = '1.5em';
export const FONT_SIZE_16 = '0.89em';
export const FONT_SIZE_36 = '2.25em';
