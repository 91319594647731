import React from 'react';

const TestNavIcon = props => (
  <svg width={14.757} height={14.756} {...props}>
    <defs>
      <style>{'.test_side_icon_white_svg__a{fill: currentColor;}'}</style>
    </defs>
    <path
      className="test_side_icon_white_svg__a"
      d="M3.473 3.704H.46a.23.23 0 01-.23-.23V.461a.23.23 0 01.23-.23h3.013a.23.23 0 01.23.23v3.013a.23.23 0 01-.23.23zM.69 3.244h2.553V.691H.69z"
    />
    <path
      className="test_side_icon_white_svg__a"
      d="M3.474 3.934H.461a.461.461 0 01-.46-.46V.461a.461.461 0 01.46-.46h3.013a.461.461 0 01.46.46v3.013a.461.461 0 01-.46.46zM.461 3.243v.23h3.014V.46h-.23a.23.23 0 01.23.23v2.553a.23.23 0 01-.23.23H.692a.23.23 0 01-.232-.23zm.46-.23h2.093V.921H.921zM.461.46v.23a.23.23 0 01.23-.23zM14.301 3.704h-3.013a.23.23 0 01-.23-.23V.461a.23.23 0 01.23-.23h3.013a.23.23 0 01.23.23v3.013a.23.23 0 01-.23.23zm-2.783-.46h2.553V.691h-2.553z"
    />
    <path
      className="test_side_icon_white_svg__a"
      d="M14.297 3.934h-3.013a.461.461 0 01-.46-.46V.461a.461.461 0 01.46-.46h3.013a.461.461 0 01.46.46v3.013a.461.461 0 01-.46.46zm-3.013-.691v.23h3.014V.46h-.23a.23.23 0 01.23.23v2.553a.23.23 0 01-.23.23h-2.553a.23.23 0 01-.232-.23zm.46-.23h2.093V.921h-2.093zM11.284.46v.23a.23.23 0 01.23-.23zM14.301 14.527h-3.013a.23.23 0 01-.23-.23v-3.013a.23.23 0 01.23-.23h3.013a.23.23 0 01.23.23v3.013a.23.23 0 01-.23.23zm-2.783-.46h2.553v-2.553h-2.553z"
    />
    <path
      className="test_side_icon_white_svg__a"
      d="M14.297 14.757h-3.013a.461.461 0 01-.46-.46v-3.013a.461.461 0 01.46-.46h3.013a.461.461 0 01.46.46v3.013a.461.461 0 01-.46.46zm-3.013-.691v.23h3.014v-3.013h-.23a.23.23 0 01.23.23v2.553a.23.23 0 01-.23.23h-2.553a.23.23 0 01-.232-.23zm.46-.23h2.093v-2.092h-2.093zm-.46-2.553v.23a.23.23 0 01.23-.23z"
    />
    <g>
      <path
        className="test_side_icon_white_svg__a"
        d="M3.473 14.527H.46a.23.23 0 01-.23-.23v-3.013a.23.23 0 01.23-.23h3.013a.23.23 0 01.23.23v3.013a.23.23 0 01-.23.23zm-2.783-.46h2.553v-2.553H.69z"
      />
      <path
        className="test_side_icon_white_svg__a"
        d="M3.474 14.757H.461a.461.461 0 01-.46-.46v-3.013a.461.461 0 01.46-.46h3.013a.461.461 0 01.46.46v3.013a.461.461 0 01-.46.46zm-3.013-.691v.23h3.014v-3.013h-.23a.23.23 0 01.23.23v2.553a.23.23 0 01-.23.23H.692a.23.23 0 01-.232-.23zm.46-.23h2.093v-2.092H.921zm-.46-2.553v.23a.23.23 0 01.23-.23z"
      />
    </g>
    <g>
      <path
        className="test_side_icon_white_svg__a"
        d="M1.799 11.513a.23.23 0 01-.23-.23V3.477a.23.23 0 01.23-.23.23.23 0 01.23.23v7.809a.23.23 0 01-.23.227z"
      />
      <path
        className="test_side_icon_white_svg__a"
        d="M1.799 11.747a.461.461 0 01-.46-.46V3.478a.461.461 0 01.46-.46.461.461 0 01.46.46v7.809a.461.461 0 01-.46.46zm0-8.27zM12.802 11.513a.23.23 0 01-.23-.23V3.477a.23.23 0 01.23-.23.23.23 0 01.23.23v7.809a.23.23 0 01-.23.227z"
      />
      <path
        className="test_side_icon_white_svg__a"
        d="M12.802 11.747a.461.461 0 01-.46-.46V3.478a.461.461 0 01.46-.46.461.461 0 01.46.46v7.809a.461.461 0 01-.46.46zm0-8.27z"
      />
      <g>
        <path
          className="test_side_icon_white_svg__a"
          d="M11.283 2.196H3.474a.23.23 0 01-.23-.23.23.23 0 01.23-.23h7.809a.23.23 0 01.23.23.23.23 0 01-.23.23z"
        />
        <path
          className="test_side_icon_white_svg__a"
          d="M11.283 2.431H3.474a.461.461 0 01-.46-.46.461.461 0 01.46-.46h7.809a.461.461 0 01.46.46.461.461 0 01-.46.46zm0-.46z"
        />
      </g>
      <g>
        <path
          className="test_side_icon_white_svg__a"
          d="M11.283 13.019H3.474a.23.23 0 01-.23-.23.23.23 0 01.23-.23h7.809a.23.23 0 01.23.23.23.23 0 01-.23.23z"
        />
        <path
          className="test_side_icon_white_svg__a"
          d="M11.283 13.25H3.474a.461.461 0 01-.46-.46.461.461 0 01.46-.46h7.809a.461.461 0 01.46.46.461.461 0 01-.46.46zm0-.46z"
        />
      </g>
    </g>
  </svg>
);

export default TestNavIcon;
