import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import DropdownMenu from 'components/Navbar/DropdownMenu';
import {
  DocsDropdownList,
  IntegrateDropdownList,
  ProfileDropdownList,
  LocaleDropdownList,
} from 'components/Navbar/DropdownNavLists';

import {
  FONT_SIZE_DOCS_COPY,
  JAVA_GREEN,
  MONA_LISA_PINK,
  COLOR_CTA_HOVER_BG,
} from 'helper/constants/styles';
import {
  AUTHED_ROUTES,
  LOGIN_ROUTE,
  androidDocsLink,
  iOSDocsLink,
} from 'helper/constants/constants';
import strings, { GetLocaleString } from 'helper/localization/localization.js';

import SvgGlobe from 'images/globe.jsx';

const StyledListContainer = styled.div`
  display: flex;

  ${props =>
    (props.screen === 'mobile'
      ? css`
          flex-direction: column;
          background-color: #152a3e;
          overflow-x: scroll;
          max-height: fit-content;
          height: 70vh;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        `
      : css`
          flex-direction: row;
          justify-content: space-between;
          text-align: left;
          padding: 22px 0 0 2vw;
          height: 3rem;
        `)}
  width: 100%;
`;

const StyledListElement = styled.li`
  ${props =>
    (props.screen === 'mobile'
      ? css`
          border-top: 1px solid #616467;
          display: inline-block;
        `
      : css`
          display: inline-block;
          margin-right: 2rem;
          margin-bottom: 0;
        `)}

  &:last-child {
    margin-right: 0;
  }

  > a {
    ${props =>
    (props.screen === 'mobile'
      ? css`
            display: flex;
            padding: 25px 15px 25px 30px;
            height: 70px;
            align-items: center;
          `
      : css`
            display: inline;
          `)}

    background-color: ${props => (props.important ? `${MONA_LISA_PINK}` : null)};
    padding: ${props => (props.important ? '9px 26px;' : null)};
    border-radius: ${props => (props.important ? '6px' : null)};
    color: ${props => (props.important ? '#0D1040' : '#fff')};
    font-weight: ${props => (props.important ? '700' : 'normal')};
    font-size: ${FONT_SIZE_DOCS_COPY};
    text-decoration: none;
    width: 100%;

    &:hover {
      ${({ important }) => (important ? `
        background-color: ${COLOR_CTA_HOVER_BG};
      ` : `
        color: ${JAVA_GREEN};
      `)}
    }
  }
`;
StyledListElement.displayName = 'StyledListElement(NavLinks)';

const LocaleString = styled.p`
  margin: 0 1rem;
`;

const ListLink = ({ id, screen, href, important, resetNav, to, children, target }) => (
  <StyledListElement id={`qa-nav-link-${id}-link`} screen={screen} important={important}>
    {href ? (
      <a rel="noopener noreferrer" onClick={resetNav} href={href} target={target}>
        {children}
      </a>
    ) : (
      <Link onClick={resetNav} to={to}>
        {children}
      </Link>
    )}
  </StyledListElement>
);
ListLink.displayName = 'ListLink(NavLinks)';

const NavLinks = ({ resetNav, screen, user }) => {
  const ProfileLink = user ? (
    <DropdownMenu resetNav={resetNav} Links={ProfileDropdownList} title={strings.profile} />
  ) : (
    <ListLink id="log-in" to={LOGIN_ROUTE}>{strings.signIn}</ListLink>
  );

  const MobileProfileLink = user ? (
    <>
      <ListLink id="user-profile" resetNav={resetNav} screen={screen} to={AUTHED_ROUTES.dashboard}>
        {strings.profile}
      </ListLink>
      <ListLink id="log-out" resetNav={resetNav} screen={screen} to={AUTHED_ROUTES.signout}>
        {strings.signOut}
      </ListLink>
    </>
  ) : (
    <ListLink id="log-in" resetNav={resetNav} screen={screen} to={LOGIN_ROUTE}>
      {strings.signIn}
    </ListLink>
  );

  const navLinks =
    screen !== 'mobile' ? (
      <>
        <ul style={{ display: 'flex' }}>
          <DropdownMenu resetNav={resetNav} Links={DocsDropdownList} title={strings.doc} />
          <DropdownMenu
            resetNav={resetNav}
            Links={IntegrateDropdownList}
            title={strings.integrateMimi}
          />
        </ul>
        <ul style={{ marginLeft: '0px', display: 'flex' }}>
          <ListLink id="contact-sales" resetNav={resetNav} to="/contact-sales" important>
            {strings.contactSales}
          </ListLink>
          {ProfileLink}
          <DropdownMenu id="locale-globe" resetNav={resetNav} Links={LocaleDropdownList} Img={SvgGlobe} />
        </ul>
      </>
    ) : (
      <>
        <ul style={{ display: 'flex', flexDirection: 'column', overflowX: 'scroll', margin: '0' }}>
          <ListLink
            id="android-docs"
            resetNav={resetNav}
            target="_blank"
            rel="noopener noreferrer"
            screen={screen}
            href={androidDocsLink}
          >
            {strings.androidDocs}
          </ListLink>
          <ListLink
            id="ios-docs"
            resetNav={resetNav}
            target="_blank"
            rel="noopener noreferrer"
            screen={screen}
            href={iOSDocsLink}
          >
            {strings.iosDocs}
          </ListLink>
          <ListLink id="contact-sales" resetNav={resetNav} screen={screen} to="/contact-sales">
            {strings.contactSales}
          </ListLink>
          {MobileProfileLink}
          <ListLink id="locale-select" resetNav={resetNav} screen={screen} to="/language">
            {
              <>
                <SvgGlobe />
                <LocaleString>{GetLocaleString()}</LocaleString>
              </>
            }
          </ListLink>
        </ul>
      </>
    );

  return <StyledListContainer screen={screen}>{navLinks}</StyledListContainer>;
};

ListLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default NavLinks;
