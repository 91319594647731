import React from 'react';

const ImplementIcon = props => (
  <svg width={15.757} height={13.028} {...props}>
    <defs>
      <style>{'.implement_side_icon_white_svg__a{fill: currentColor;}'}</style>
    </defs>
    <path
      className="implement_side_icon_white_svg__a"
      d="M14.851 0H3.634a.906.906 0 00-.906.905v3.151h1.811V1.811h9.406v9.406H4.539V8.829H2.728v3.294a.906.906 0 00.906.906h11.217a.9.9 0 00.906-.9V.905A.905.905 0 0014.851 0z"
    />
    <path
      className="implement_side_icon_white_svg__a"
      d="M4.54 7.113h3.314l-1.4 1.7a.655.655 0 00.087.922.652.652 0 00.417.15.653.653 0 00.505-.237l2.292-2.772a.655.655 0 00-.013-.849L7.69 3.398a.656.656 0 00-.921-.114.656.656 0 00-.113.92l1.247 1.6H.657a.655.655 0 00-.655.656.655.655 0 00.656.655h3.885z"
    />
  </svg>
);

export default ImplementIcon;

