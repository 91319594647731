import React from 'react';

const AndroidIcon = props => (
  <svg width={12.673} height={24.552} {...props}>
    <defs>
      <style>{'.android_icon_svg__a{fill:currentColor}'}</style>
    </defs>
    <path
      className="android_icon_svg__a"
      d="M10.907.097H1.765a1.78 1.78 0 00-1.67 1.869v20.621a1.78 1.78 0 001.67 1.869h9.139a1.78 1.78 0 001.67-1.869V1.966A1.78 1.78 0 0010.907.097zm.768 22.49a.886.886 0 01-.768.967H1.765a.886.886 0 01-.768-.967v-.354h10.678v.354zm0-1.257H.999V3.383h10.676V21.33zm0-18.849H.999v-.516a.886.886 0 01.768-.967h9.139a.886.886 0 01.768.967v.516z"
    />
    <path
      className="android_icon_svg__a"
      d="M10.906 24.552H1.768a1.876 1.876 0 01-1.767-1.965V1.966A1.876 1.876 0 011.768.001h9.139a1.876 1.876 0 011.767 1.965v20.621a1.876 1.876 0 01-1.768 1.965zM1.768.193A1.684 1.684 0 00.194 1.965v20.621a1.684 1.684 0 001.574 1.772h9.139a1.684 1.684 0 001.574-1.772V1.965A1.684 1.684 0 0010.907.193zm9.139 23.456H1.768a.981.981 0 01-.865-1.063v-.451h10.865v.451a.981.981 0 01-.862 1.064zm-9.811-1.321v.258a.791.791 0 00.672.87h9.139a.791.791 0 00.672-.87v-.258zm10.676-.9H.902V3.288h10.866zm-10.676-.193h10.482V3.482H1.096zM11.768 2.578H.902v-.612A.981.981 0 011.767.903h9.139a.981.981 0 01.865 1.063zM1.092 2.385h10.486v-.419a.791.791 0 00-.672-.87H1.768a.791.791 0 00-.672.87z"
    />
  </svg>
);

export default AndroidIcon;
