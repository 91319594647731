import React from 'react';

const SvgDocsArrowClose = props => (
  <svg width={7.069} height={4.587} {...props}>
    <path
      d="M3.535 4.587L0 1.053 1.053 0l2.482 2.481L6.016 0l1.053 1.053z"
      fill="#fff"
    />
  </svg>
);

export default SvgDocsArrowClose;
