import React from 'react';
import Select from 'react-select';
import {
  COLOR_DOCS_LINKS_BG,
  COLOR_FORM_FIELD_BG,
  COLOR_FORM_PLACEHOLDER,
  JAVA_GREEN,
  HUGE_STONE,
} from 'helper/constants/styles';

const LINE_COLOR = COLOR_FORM_PLACEHOLDER;

const customStyles = {
  container: provided => ({
    ...provided,
    width: '90%',
    marginLeft: '5%',
  }),
  option: (provided, state) => ({
    ...provided,
    paddingLeft: '20px',
    paddingRight: '20px',
    color: 'white',
    cursor: 'pointer',
    background: COLOR_FORM_FIELD_BG,
    borderLeft: `3px solid ${state.data.current ? JAVA_GREEN : 'transparent'}`,
    '&:hover': {
      background: HUGE_STONE,
      color: JAVA_GREEN,
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
  }),
  control: provided => ({
    ...provided,
    background: COLOR_DOCS_LINKS_BG,
    marginBottom: '1rem',
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderColor: LINE_COLOR,
    borderWidth: '1px',
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {
      background: COLOR_FORM_FIELD_BG,
      border: `1px solid ${JAVA_GREEN}`,
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: LINE_COLOR,
    '&:hover': {
      backgroundColor: JAVA_GREEN,
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: LINE_COLOR,
    '&:hover': {
      color: JAVA_GREEN,
    },
  }),
  menu: provided => ({
    ...provided,
    textTransform: 'capitalize',
    background: COLOR_FORM_FIELD_BG,
  }),
  groupHeading: provided => ({
    ...provided,
    paddingLeft: '20px',
    fontSize: '100%',
    textTransform: 'capitalize',
    color: JAVA_GREEN,
    fontWeight: 700,
  }),
};

export default ({ docs, selectVersion }) => {
  if (!docs || !docs.meta || !docs.platform || !docs.version) {
    return null;
  }

  const platformVersions = Object.keys(docs.meta).map(platform => ({
    os: platform,
    label: 'Versions',
    options: docs.meta[platform].map(({ version, firstPage }) => ({
      value: firstPage,
      version,
      label: `${platform} ${version}`,
      current: version === docs.version && platform === docs.platform,
    })),
  }));

  const current = platformVersions
    .find(o => o.os === docs.platform).options
    .find(o => o.version === docs.version);

  return (
    // isSearchable props set to disable mobile keyboards
    <Select
      styles={customStyles}
      isSearchable={false}
      current={{ version: docs.version }}
      options={platformVersions}
      placeholder="Select version"
      onChange={selectVersion}
      value={current}
    />
  );
};
