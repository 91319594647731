import React from 'react';

const IOSIcon = props => (
  <svg width={14.108} height={24.367} {...props}>
    <defs>
      <style>{'.ios_icon_svg__a{fill:currentColor}'}</style>
    </defs>
    <path
      className="ios_icon_svg__a"
      d="M1.759.144A1.614 1.614 0 00.147 1.756V22.61a1.614 1.614 0 001.612 1.618h10.6a1.614 1.614 0 001.612-1.612V1.762A1.614 1.614 0 0012.359.15zm11.6 18.584H.746V3.641h12.613zm-1.01 4.9H1.759a1.012 1.012 0 01-1.01-1.01v-3.29h12.61v3.285a1.012 1.012 0 01-1.007 1.008zm1.01-21.865v1.278H.746V1.763a1.012 1.012 0 011.01-1.01h10.6a1.012 1.012 0 011.003 1.003z"
    />
    <path
      className="ios_icon_svg__a"
      d="M12.352 24.367h-10.6a1.758 1.758 0 01-1.756-1.756V1.757A1.758 1.758 0 011.752.001h10.6a1.758 1.758 0 011.756 1.756v20.854a1.758 1.758 0 01-1.756 1.756zM1.752.288A1.47 1.47 0 00.284 1.756V22.61a1.47 1.47 0 001.468 1.468h10.6a1.47 1.47 0 001.468-1.468V1.756A1.47 1.47 0 0012.352.288zm10.6 23.477h-10.6a1.156 1.156 0 01-1.154-1.154v-3.427h12.9v3.429a1.156 1.156 0 01-1.146 1.152zM.89 19.465v3.141a.867.867 0 00.866.866h10.6a.867.867 0 00.866-.866v-3.141zm12.616-.6H.606V3.49h12.9zM.89 18.584h12.325v-14.8H.89zm12.616-15.4H.606V1.762A1.156 1.156 0 011.76.608h10.6a1.156 1.156 0 011.154 1.154zM.89 2.896h12.325V1.762a.867.867 0 00-.866-.866h-10.6a.867.867 0 00-.866.866z"
    />
    <g>
      <path
        className="ios_icon_svg__a"
        d="M7.054 20.081a1.442 1.442 0 101.439 1.442 1.444 1.444 0 00-1.439-1.442zm0 2.282a.84.84 0 11.84-.84.841.841 0 01-.84.84z"
      />
      <path
        className="ios_icon_svg__a"
        d="M7.054 23.109a1.586 1.586 0 111.586-1.586 1.588 1.588 0 01-1.586 1.586zm0-2.884a1.3 1.3 0 101.3 1.3 1.3 1.3 0 00-1.3-1.3zm0 2.282a.984.984 0 11.984-.984.985.985 0 01-.984.984zm0-1.68a.7.7 0 10.7.7.7.7 0 00-.7-.7z"
      />
    </g>
  </svg>
);

export default IOSIcon;
