import React from 'react';
import styled from 'styled-components';

import BackgroundWavesImg from 'images/BackgroundWaves.svg';

const Background = styled.div`
  background-image: url(${BackgroundWavesImg});
  background-repeat: repeat-x;
  background-position: -15rem -15rem;
  background-size: auto;
  max-height: 100vh;
`;

const BackgroundWaves = ({ children }) => (<Background>{children}</Background>);

export default BackgroundWaves;
