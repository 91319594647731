import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { I18n } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';

import 'helper/amplify-config';
import BackgroundWaves from 'components/BackgroundWaves/BackgroundWaves';

import strings from 'helper/localization/localization.js';

import { getCurrentUser, logoutUser } from '../state/user';
import { clearFiles } from '../state/files';
import { setupSidebar } from '../state/docs';
import rootReducer from '../state/root-reducer';

class RootChild extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(getCurrentUser());
    Hub.listen('auth', this.getUserState);
    Hub.listen('Routing', this.handleRouteChange);
    // Check typeof window for SSR
    if (typeof window !== 'undefined') {
      if (
        window.localStorage !== undefined
        // getItem() for local Storage always returns null if item is undefined
        && window.localStorage.getItem('locale') !== null
      ) {
        // Set Locale if stored locally
        strings.setLanguage(window.localStorage.getItem('locale'));
        I18n.setLanguage(window.localStorage.getItem('locale'));
      } else if (window.localStorage.getItem('locale') === null) {
        // If value of locale in localStorage not set, default to English
        window.localStorage.setItem('locale', 'en-US');
      }
    }
  }

  getUserState = ({ payload: { event } }) => {
    if (event === 'signIn') {
      this.props.dispatch(getCurrentUser());
    }
    if (event === 'signOut') {
      this.props.dispatch(logoutUser());
      // remove all user files during logout
      this.props.dispatch(clearFiles());
    }
  }

  handleRouteChange = ({ payload: { data } }) => {
    const splitPath = data.to.split('/');

    // if the url starts with /documentation/ we know we're on a docs page
    if (splitPath[1] === 'documentation') {
      const platform = splitPath[2];
      const version = splitPath[3];

      // no point in setting this multiple times, if it's already setup we don't need to do it again
      if (this.props.docs.platform !== platform || this.props.docs.version !== version) {
        this.props.dispatch(setupSidebar(platform, version));
      }
    }
  }

  render() {
    return this.props.children;
  }
}

const RootChildConnected = connect(({ user, docs }) => ({ user, docs }))(RootChild);

// if you have redux devtools extension installed
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : // eslint-disable-line no-underscore-dangle
  compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk),
  ),
);

export default ({ element }) => (
  <Provider store={store}>
    <RootChildConnected>
      <BackgroundWaves>
        {element}
      </BackgroundWaves>
    </RootChildConnected>
  </Provider>
);
