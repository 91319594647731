import { createGlobalStyle } from 'styled-components';
// for syntax highlighting of code blocks in markdown files
import 'prismjs/themes/prism-tomorrow.css';

import {
  COLOR_FORM_PLACEHOLDER,
  COLOR_GENERIC_BG,
  COLOR_GENERIC_TEXT,
  FONT_SIZE_ROOT,
  FONT_SIZE_DOCS_H1,
  FONT_SIZE_DOCS_H2,
  FORM_ERROR_RED,
  JAVA_GREEN,
  MONA_LISA_PINK,
} from 'helper/constants/styles';

const GlobalStyle = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: 0;
    box-sizing: border-box;
  }

  *:before {
    box-sizing: inherit;
  }
  *:after {
    box-sizing: inherit;
  }

  /* make full height containers */
  html,
  body,
  #___gatsby,
  #___gatsby > div,
  #main {
    margin: 0;
    padding: 0;
    display: flex;
    min-height: 100%;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
  }

  html {
    background-color: ${COLOR_GENERIC_BG};
    color: ${COLOR_GENERIC_TEXT};
    font-size: ${FONT_SIZE_ROOT};
    box-sizing: border-box;
    word-break: break-word;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    font-weight: 400;
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 1.45rem;
    text-rendering: optimizeLegibility;
  }

  h1 {
    font-size: ${FONT_SIZE_DOCS_H1};
    font-weight: 700;
  }

  h2 {
    font-size: ${FONT_SIZE_DOCS_H2};
  }

  h3 {
    font-size: 1.34em;
  }

  h4 {
    font-size: 1.2em;
  }

  h5 {
    font-size: 0.85028em;
  }

  h6 {
    font-size: 0.78405em;
  }

  ol,
  ul {
    margin-left: 1.45rem;
    margin-top: 0;
    padding-left: 0;
    list-style: none;
  }

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: ${JAVA_GREEN};
  }

  img {
    border-style: none;
    max-width: 100%;
  }

  input,
  textarea {
    font-family: inherit;
  }

  input::placeholder,
  textarea::placeholder {
    color: ${COLOR_FORM_PLACEHOLDER};
  }

  div[class*="Section__container_"] {
    a[class*="Toast__toastClose_"]::before,
    a[class*="Toast__toastClose_"]::after {
      background: ${FORM_ERROR_RED};
    }
  }

  div[class*="Form__formSection_"] {
    a[class*="Anchor__a_"]:hover {
      text-decoration: underline !important;
    }

    /* This input rule below is needed to stop Firefox from displaying its own red outline
    around the email input field when it is not a correct email format */
    input[class*="Input__input_"] {
      box-shadow:none;
    }

    input[class*="Input__input_"]:focus {
      border: ${JAVA_GREEN} 1px solid !important;
    }

    input[class*="Input__input_"]:invalid {
      border: ${MONA_LISA_PINK} 1px solid !important;
    }

    /* Have error styling if input error is returned to ID; remove if not empty input */
    input[data-validation="input-error"]:placeholder-shown {
      border: ${MONA_LISA_PINK} 1px solid !important;
    }
  }

  div[class*="Form__formRow_"] {
    display: flex;
    label[class*="Input__label_"] {
      color: #ffffff;
    }
  }

  @media only screen and (max-width: 480px) {
    html {
      font-size: 100%;
    }
  }
`;

export default GlobalStyle;
