/* eslint-disable import/prefer-default-export */
import fetch from 'isomorphic-fetch';
import Timeout from 'await-timeout';

const { LAMBDA_API_URL } = require('../../serverless/shared-constants');

const apiRequest = async (url, options) => {
  const allOptions = {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
    },
  };

  const fetchTimeout = new Timeout();

  try {
    // Some internal errors while calling fetch to 'contact' backend can result in
    // uncaught INTERNAL errors and finally a browser timeout error.
    // timeouts in browsers avg. 4mins,
    // setting Timeout() instance within Promise.race() to provide a
    // shortened timeout response that resolves a new Error()
    const response = await Promise.race([
      fetch(url, allOptions),
      fetchTimeout.set(40000, 'Request timed out!'),
    ]);

    const { status, statusText } = response;

    // response can be json or text, so get text and try to parse it as json
    const text = await response.text();

    let resText;
    try {
      resText = JSON.parse(text);
    } catch (err) {
      resText = text;
    }
    // response status code was not 2xx
    if (!response.ok) {
      const errObj = {
        status,
        statusText,
        res: resText,
      };
      throw errObj;
    }
    return resText;
  } catch (e) {
    throw e;
  } finally {
    // stop all promises in Promise.race getting fulfilled wuth timer cleaning
    fetchTimeout.clear();
  }
};

export const submitContactForm = (values = {}) => (
  apiRequest(`${LAMBDA_API_URL}/contact`, {
    method: 'POST',
    body: JSON.stringify(values),
  })
);

export const listBinaries = async (token, sdkFlavor, platform) => (
  apiRequest(`${LAMBDA_API_URL}/files/${sdkFlavor}/list/${platform}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
);

export const getFiles = async jwtToken => (
  apiRequest(`${LAMBDA_API_URL}/files/all`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  })
);

export const downloadFile = async (href, jwtToken) => {
  const cloudfrontFileURL = await apiRequest(`${LAMBDA_API_URL}/files/${href}?redirect=false`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  // open browser download dialog
  window.open(cloudfrontFileURL, '_blank');
};
